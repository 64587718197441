<template>
  <div :style="computedStyle" v-show="!isHidden">
    <span class="registry_fields_label">{{ label }}</span>
    <el-select
      :size="size"
      :name="name"
      v-model="localModel"
      :style="styleSelect"
      :disabled="isDisabled"
      :placeholder="placeholder || $locale.main.placeholder.select"
      :clearable="clearable"
      filterable
      collapse-tags
      :multiple="multiple"
      @clear="localModel = (multiple ? [] : null);$emit('input', localModel)"
      @change="$emit('input', localModel)"
    >
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import DisableMixin from '../disable_properties_mixin'

export default {
  mixins: [mixin, DisableMixin, VisibleMixin],
  name: 'a-select',
  props: {
    model: {
      frozen: true
    },
    size: {
      type: String,
      description: 'Размер'
    },
    defaultValue: {
      type: String,
      description: 'По умолчанию'
    },
    name: {
      type: String,
      description: 'Атрибут'
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    stateId: {
      description: 'Состояние (id)',
      type: String
    },
    initialOptions: {
      type: Array,
      frozen: true,
      default: () => [],
      description: 'Набор значений'
    },
    multiple: {
      type: Boolean,
      default: false,
      description: 'Множественный выбор'
    },
    registryId: {
      type: Number,
      description: 'id реестра для типизированных селектов'
    },
    attributeId: {
      type: Number,
      description: 'id атрибута для типизированных селектов'
    },
    label: {
      type: String,
      description: 'Название'
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    disabled: {
      type: Boolean,
      description: 'Заблокированное',
      frozen: true,
      default: false
    },
    clearable: {
      type: Boolean,
      description: 'Очищаемое',
      default: false
    },
    block: {
      type: Boolean,
      description: 'Во всю строку',
      default: false
    },
    filters: {
      type: Array,
      editor: 'Filters'
    }
  },
  watch: {
    dataFilters () {
      this.loadData()
    },
    value (val) {
      if (val !== this.localModel) {
        this.localModel = val
      }
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS

      if (!this.block) {
        css += ';display: inline-block'
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }

      return css
    },
    styleSelect () {
      let css = ''
      if (!this.block) {
        css += ';display: inline-block'
      }
      if (this.width) {
        css = ';display: block'
      }
      return css
    },
    dataFilters () {
      let filters = []
      if (this.filters) {
        this.filters.forEach((item) => {
          let type = `eq`
          if (item.isXref) {
            type = `eqx`
          }
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
            }
          } else if (item.type === 'constant' && item.alias) {
            filters.push(`${item.alias},${type},${item.attribute}`)
          }
        })
      }
      return filters
    }
  },
  data () {
    return {
      localModel: this.model,
      options: this.initialOptions
    }
  },
  mounted () {
    if (this.defaultValue && !this.model) {
      let value = this.defaultValue
      if (value === '{{user_id}}') {
        value = this.$store.getters['Authorization/userId']
      }
      this.localModel = (this.multiple ? [parseInt(value)] : parseInt(value))
      this.$emit('input', this.localModel)
    }
    this.loadData()
  },
  methods: {
    async loadData () {
      if (this.registryId && this.attributeId) {
        let params = []
        this.dataFilters.forEach((filter, index) => {
          params.push(`filter[${index}]=${filter}`)
        })
        if (this.stateId) {
          params.push(`state_id=${this.stateId}`)
        }
        let answer = await this.$http.get(`${this.$config.api}/registryservice/xref/?registry_id=${this.registryId}&attribute_id=${this.attributeId}&${params.join('&')}`)
        this.options = answer.data
      }
    }
  }
}
</script>

<style scoped>

</style>
